import React from 'react';
import Helmet from 'react-helmet';

import {Link} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import logo_cmi from '../images/contact/logo_cmi.png';

const ContactPage = () => (
    <Layout>
        <SEO title="Contact"/>
        <Helmet bodyAttributes={{class: 'contact'}}/>
        <div className="multipageBox p14 p14c7">
            <div className="staticContent" id="staticContent_contact">
                <h1>Contact</h1>
                {/*<p>Record Label</p>*/}
                {/*<p>CMI / Cible Music International</p>*/}
                {/*<p>Canada / 9305 Rue D'Iberville</p>*/}
                {/*<p>H1Z 2R5 Montr&#233;al Qu&#233;bec</p>*/}
                {/*<p>&#160;</p>*/}
                {/*<p>Bureau Succursale Suisse</p>*/}
                {/*<p>Label CMI Cible Music International</p>*/}
                {/*<p>La Holena 32 / 1634</p>*/}
                {/*<p>La Roche / Fribourg</p>*/}
                {/*<p>&#160;</p>*/}
                <p>Label CMI&#160;:</p>
                <p>
                    <a href="mailto:contact.labelcmi@gmail.com">contact.labelcmi@gmail.com</a>
                </p>
                <p>&#160;</p>
                <p>
                    <img
                        alt="Cible Music International"
                        height="158"
                        id="cmiLogo"
                        src={logo_cmi}
                        width="100"
                    />
                </p>
                <p>&#160;</p>
                <p>&#160;</p>
                <p>&#160;</p>
                <p>&#160;</p>
                <h1>Remerciements</h1>
                <p>
                    Leelo Pascal est support&#233; par de nombres personnes et
                    organisations. D&#233;couvrez la{' '}
                    <Link to="/remerciements/">page remerciements</Link>.
                </p>
            </div>
        </div>
        <div className="multipageBox p16 p16c1">
        </div>
    </Layout>
);

export default ContactPage;
